import { Component, Input } from '@angular/core';

@Component({
  selector: 'div[appProductCard]',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent {

  @Input() product;
}
