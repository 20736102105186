import { Component, Input, OnInit } from '@angular/core';
import { take, tap } from 'rxjs/operators';
import { PostService } from 'src/app/blog/services/post-services/post.service';

@Component({
  selector: 'div[appProductGrid]',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss']
})
export class ProductGridComponent implements OnInit {

  @Input() mode: 'banner'|'ad' = 'banner';
  @Input() tags: string[];
  products;
  
  constructor(private postService: PostService) { }

  ngOnInit() {
    if (this.tags) {
      this._getProductsByTag(this.tags).subscribe();
    }
  }

  private _getProductsByTag(tags) {
    return this.postService.getProductsByTag(tags).pipe(
      take(1),
      tap(products => products ? this.products = products : []),
      tap(products => console.dir(this.products))
    );
  }

}
