import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'div[appRoot]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit { 

  hideCookiesBanner: boolean;

  ngOnInit() {
    if (environment.gtag) {
      this.loadGTag();
    }
    this.hideCookiesBanner = !!localStorage.getItem('dlv_cookies_consent') ? true : false;
  }

  public accepted() {
    this.hideCookiesBanner = true;
    localStorage.setItem('dlv_cookies_consent', 'true');
  }

  private loadGTag() {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
        j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-K5RVN3SS');
    `;
    document.head.appendChild(script);

    // Noscript fallback (para navegadores sin JS)
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K5RVN3SS" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }

}
