import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PostService } from '../../services/post-services/post.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingResolver  {

  constructor (private postService: PostService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.postService.getPost(state.url).pipe(
      take(1)
    );
  }
}
